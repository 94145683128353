import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

export enum YollaType {
  DESKTOP_MEDRECT = 'desktop-medrec-template',
  DESKTOP_LEADERBOARD = 'desktop-leaderboard-template',
  MOBILE_LEADERBOARD = 'mobile-leaderboard-template',
}

export enum YollaSubType {
  DESKTOP_MEDRECT = '320x250',
  DESKTOP_LARGERECT = '336x280',
  DESKTOP_HALFPAGE = '300x600',
  DESKTOP_LEADERBOARD = '728x90',
  MOBILE_LEADERBOARD = '320x50',
  MOBILE_ADHESION = '320x50',
}

export interface YollaProps extends IntrinsicProps {
  type: string;
  subType: string;
  num: number;
}

export default createComponent<YollaProps>('AdYolla', {}, function AdYolla({ }, props) {
  // Append 'ad_' prefix to the subType for use as a class name
  const subTypeClass = `ad_${props.subType}` + `_` + props.num;

  return (
    <div className={`ympb_target ${subTypeClass}`} data-type={props.type} data-subtype={props.subType}></div>

  );
});
