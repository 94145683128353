import { gameQuery } from '@/apis/drupal/game';
import axios from '@/apis/drupal/instance';
import { toQuery } from '@/common/util/networkRequest';
import { DrupalNewGamesData } from '@/models/NewGames';
import { GameBasic, formatGameBasic } from '@/util/formatters';

function arrangeItems (firstRow: any[], secondRow: any[]): any[] {
  const result: any[] = [];

  const length = firstRow.length + secondRow.length;
  
  for (let i = 0; i < length; i++) {
    const patternIter = (i + 2) % 6;

    if (patternIter <= 2) {
      if (firstRow[ 0 ]) result.push(firstRow.shift());
    } else {
      if (secondRow[ 0 ]) result.push(secondRow.shift());
    }
  }

  return result;
}

const mobileOnlyFilter = {
  'mobile': {
    'group': {
      'conjunction': 'OR'
    }
  },
  'field_is_mobile': {
    'condition': {
      'path': 'field_is_mobile',
      'value': 1,
      'memberOf': 'mobile'
    }
  },
  'field_is_mobile_playable': {
    'condition': {
      'path': 'field_is_mobile_playable',
      'value': 1,
      'memberOf': 'mobile'
    }
  }
};

async function jsonApiQuery (query = {}, filter = {}, limit = 0, offset = 0) {
  return await axios.request({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_hide_from_listings': 0,
        ...filter
      },
      ...query,
      page: {
        limit,
        offset
      }
    })
  });
}

export default class HomePageGamesModel {
  static async getMostAddictingGames (limit = 0, offset = 0): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await axios.request({
        method: 'get',
        url: `/rest/block/most-addicting-games?items_per_page=${limit}&offset=${offset}`,
      });
    } catch (error) {
      throw error;
    }

    if (result.mostaddictinggames_first_row) {
      const firstRow = result.mostaddictinggames_first_row as any as GameBasic[];
      const secondRow = result.mostaddictinggames_second_row as any as GameBasic[];
      const endResult = arrangeItems(firstRow, secondRow);
      return endResult.map((game) => formatGameBasic(game));
    }

    return result.map((game) => formatGameBasic(game));
  }

  static async getHighestRatedGames (limit = 0, offset = 0, mobileOnly?: boolean): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await jsonApiQuery(
        { sort: '-field_ratingb,-field_total_votes' }, 
        mobileOnly ? mobileOnlyFilter : {},
        limit, 
        offset
      );
    } catch (error) {
      throw error;
    }

    return result.data.map((game) => formatGameBasic(game, result.included));
  }

  static async getMostPlayedGames (limit = 0, offset = 0, mobileOnly?: boolean): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await jsonApiQuery(
        { sort: '-field_hits,-published_at' },
        mobileOnly ? mobileOnlyFilter : {},
        limit, 
        offset
      );
    } catch (error) {
      throw error;
    }

    return result.data.map((game) => formatGameBasic(game, result.included));
  }

  static async getTrendingGames (limit = 0, offset = 0): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await axios.request({
        method: 'get',
        url: `/rest/block/trending-games?items_per_page=${limit}&offset=${offset}`,
      });
    } catch (error) {
      throw error;
    }

    return result.map((game) => formatGameBasic(game, result.included));
  }

  static async getClassicGames (limit = 0, offset = 0): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await axios.request({
        method: 'get',
        url: `/rest/block/classic-games?items_per_page=${limit}&offset=${offset}`,
      });
    } catch (error) {
      throw error;
    }

    return result.map((game) => formatGameBasic(game, result.included));
  }


  static async getNewGames (limit = 0, offset = 0, mobileOnly?: boolean): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await jsonApiQuery(
        { sort: '-published_at,-field_ratingb,-field_total_votes' },
        mobileOnly ? mobileOnlyFilter : {},
        limit, 
        offset
      );
    } catch (error) {
      throw error;
    }

    return result.data.map((game) => formatGameBasic(game, result.included));
  }

  static async getNewGamesQueue (limit: number, offset: number = 0): Promise<GameBasic[]> {
    let rawNewGamesData: DrupalNewGamesData[];
    try {
      rawNewGamesData = await axios.request({
        method: 'get',
        url: `/new-game-queues-by-date/json?items_per_page=${limit}&page=0&offset=${offset}`,
      });
    } catch (error) {
      throw error;
    }

    const games: GameBasic[] = [];
    
    for (let i = 0; i < rawNewGamesData.length; i++) {
      const rawNewGamesDataItem = rawNewGamesData[ i ];

      let rawGamesData: any[];
      try {
        rawGamesData = await axios.request({
          method: 'get',
          url: `/editorial-queue-feeder-by-nid/${rawNewGamesDataItem.field_select_games}/json`,
        });
      } catch (error) {
        throw error;
      }

      games.push(...rawGamesData.map(rawGame => formatGameBasic(rawGame)));
    }

    return games;
  }

  static async getTakeoverQueue (limit = 0, offset = 0): Promise<GameBasic[]> {
    let result: any;

    try {
      result = await axios.request({
        method: 'get',
        url: `/rest/block/takeover-games?items_per_page=${limit}&offset=${offset}`,
      });
    } catch (error) {
      throw error;
    }

    if (Array.isArray(result)) return [];

    return Object
      .values(result)
      .flat()
      .map((game) => formatGameBasic(game));
  }
}
